<template>
    <section v-if="!$leila.auth.me">
        <bxs-layout>
            <bxs-btn
            to="/signin"
            block
            color="black"
            light
            between
            uppercase
            min-height="60px"
            append-icon="chevron-right">
                <bxs-icon
                name="person-fill"
                class="mr-ui"></bxs-icon>

                <span>Accedi e prenota!</span>
            </bxs-btn>
        </bxs-layout>
    </section>
</template>

<script>
export default {
    name: 'signin_section'
}
</script>