<template>
    <div class="headquarter-row flex nowrap">
        <div>
            <bxs-figure
            :src="item.img.url ? $filters.toStaticFile(item.img.url) : null"
            ratio="1"
            :alt="`Leila biblioteca degli oggetti ${$store.state.library ? $store.state.library.name : ''} punto di ritiro desk`"
            width="80" />
        </div>

        <div class="flex-1 px-ui">
            <div class="mb-ui">
                <p class="text-500 mb-1">{{ title }} {{ item.name }}</p>
                <p class="small">{{ item.address }}</p>
            </div>

            <hr>

            <!-- <p
            v-if="!hideOpeningTime"
            class="text-mute small">{{ item.opening_days.map(v => t[v.toLowerCase()]).join(', ') }} <br> {{ item.opening_times }}</p> -->

            <div
            v-if="item.opening_hours && item.opening_hours.length > 0"
            class="mb-ui">
                <h6 class="small">Orari di apertura</h6>

                <ul>
                    <li
                    v-for="v in item.opening_hours"
                    :key="v.id">
                        <small class="text-mute">{{ v.display_value }}</small>
                    </li>
                </ul>
            </div>

            <div v-if="item.holidays && item.holidays.length > 0">
                <h6 class="small" style="font-size: 11px;">Prossimi giorni di chiusura</h6>

                <ul>
                    <li
                    v-for="v in item.holidays"
                    :key="v.id">
                        <small class="text-mute">{{ v.inizio }} - {{ v.fine }}</small>
                    </li>
                </ul>
            </div>

            <slot />
        </div>

        <div v-if="!!$slots.actions">
            <slot name="actions" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'headquarter-row',
    props: {
        item: {
            type: Object,
            required: true
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        'hide-opening-time': {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            t: {
                monday: 'Lunedì',
                tuesday: 'Martedí',
                wednesday: 'Mercoledí',
                thursday: 'Giovedì',
                friday: 'Venerdì',
                Saturday: 'Sabato',
                Sunday: 'Domenica'
            }
        }
    }
}
</script>