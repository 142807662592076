<template>
    <bxs-data-lazy
    v-model="headquarters"
    :call="() => $leila.get('v1/headquarters')">
        <section>
            <bxs-layout>
                <div class="mb-ui">
                    <bxs-title>Punti di ritiro</bxs-title>
                </div>

                <bxs-card class="pa-ui">
                    <headquarter-row
                    v-for="item in headquarters"
                    :key="item.id"
                    :item="item"
                    class="mb-ui mb-0-last" />
                </bxs-card>
            </bxs-layout>
        </section>
    </bxs-data-lazy>
</template>

<script>
export default {
    name: 'headquarters-section',
    data () {
        return {
            headquarters: []
        }
    }
}
</script>