<template>
    <div
    :class="classes"
    :style="styles"
    @click="onClick">
        <div class="bxs-card--body">
            <div
            v-if="overlay"
            class="bxs-overlay bxs-card--body--overlay"></div>

            <div class="bxs-card--body--cnt">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import { ratio, measurable } from '@/mixins'

export default {
    name: 'bxs-card',
    mixins: [ratio, measurable],
    props: {
        'no-padding': {
            type: Boolean,
            required: false,
            default: false
        },
        'allow-overflow': {
            type: Boolean,
            required: false,
            default: true
        },
        src: {
            type: String,
            required: false,
            default: null
        },
        overlay: {
            type: Boolean,
            required: false,
            default: false
        },
        to: {
            type: String,
            required: false,
            default: null
        },
        gradient: {
            type: Boolean,
            required: false,
            default: false
        },
        elevation: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        classes () {
            return [
                'bxs-card',
                'bxs-elevation',
                {
                    ...this.ratio_classes,
                    'bxs-link': !!this.to,
                    'bxs-no-padding': this.noPadding,
                    'bxs-card-gradient': this.gradient,
                    'bxs-overflow-hidden': this.allowOverflow
                }
            ]
        },
        styles () {
            const v = {
                ...this.measurable_styles
            }
            if (this.src) v.backgroundImage = 'url(' + this.src + ')'
            return v
        }
    },
    methods: {
        onClick () {
            if (this.to) this.$router.push(this.to)
        }
    }
}
</script>

<style lang="scss" scoped>
.bxs-card {
    position: relative;
    width: 100%;
    border-radius: var(--card-border-radius);
    background-color: var(--card-background-color);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: var(--box-shadow);

    &.bxs-link {
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-image: linear-gradient(rgb(0 0 0/5%) 0 0);
            }
        }
    }

    &.bxs-no-padding {
        padding: 0;

        .bxs-card--body--cnt {
            padding: 0
        }
    }

    &.bxs-overflow-hidden {
        overflow: hidden;

        > .bxs-card--body {
            overflow: hidden;
        }
    }

    &.bxs-card-gradient {
        background-image: linear-gradient(
            -308deg,
            rgba(230, 230, 230, 0.5),
            rgba(105, 105, 105, 0.5)
        );
    }

    &--body {
        &--overlay {
            z-index: 0;
        }

        &--cnt {
            position: relative;
            padding: var(--card-padding-y) var(--card-padding-x);
        }
    }
}
</style>