<template>
    <section v-if="highlighted_products.length > 0">
        <bxs-layout>
            <div class="flex align-center mb-ui">
                <bxs-title>Oggetti in evidenza</bxs-title>

                <bxs-spacer></bxs-spacer>

                <router-link
                to="/search"
                class="text-primary">
                    <small class="mr-2">Vedi altro</small>
                    <bxs-icon name="chevron-right"></bxs-icon>
                </router-link>
            </div>

            <bxs-row>
                <bxs-col
                v-for="(item, i) in highlighted_products"
                :key="i"
                cols="2">
                    <bxs-figure
                    ratio="1"
                    :src="item.imgs.length > 0 ? $filters.toStaticFile(item.imgs[0]) : null"
                    class="bxs-pointer"
                    @click="$router.push(`/products/${item.id}`).catch(() => {})" />

                    <!-- <bxs-carousel
                    :items="arr"
                    :mousewheel="false"
                    :speed="1200"
                    :autoplay="{
                        delay: (i + 1) * 1500,
                        pauseOnMouseEnter: true,
                        stopOnLastSlide: false
                    }"
                    direction="vertical"
                    style="max-width: 100px; margin: 0 auto;">
                        <template #item="{ item }">
                            <bxs-figure
                            ratio="1"
                            :src="item.imgs.length > 0 ? $filters.toStaticFile(item.imgs[0]) : null"
                            @click="$router.push(`/products/${item.id}`).catch(() => {})" />
                        </template>
                    </bxs-carousel> -->
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </section>
</template>

<script>
import { mapState } from 'vuex'

// function splitArray (array, numVolte) {
//     const lunghezzaParte = Math.floor(array.length / numVolte)
//     const partiSeparate = []
//     let indice = 0

//     for (let i = 0; i < numVolte; i++) {
//         partiSeparate.push(array.slice(indice, indice + lunghezzaParte))
//         indice += lunghezzaParte
//     }

//     return partiSeparate
// }

export default {
    name: 'highlighted_products_section',
    computed: {
        ...mapState({
            highlighted_products: state => state.highlighted_products
            // comp_highlighted_products: state => splitArray(state.highlighted_products, 6)
        })
    }
}
</script>