<template>
    <footer>
        <socials-row class="mb-ui" />
        <p class="text-mute"><small>© 2019-{{ new Date().getFullYear() }} Leila Italia</small></p>
    </footer>
</template>

<script>
export default {
    name: 'bxs-footer'
}
</script>

<style lang="scss" scoped>
footer {
    position: relative;
    width: 100%;
    min-height: var(--footer-height);
    background-color: var(--footer-background-color);
    color: var(--footer-color);
    padding-left: var(--footer-padding-x);
    padding-right: var(--footer-padding-x);

    // display: flex;
    // flex-flow: row nowrap;
    // align-items: center;
    // justify-content: space-between;
}
</style>